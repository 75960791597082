/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Header2 = ({
  children, 
  align='left', 
  color='#8BC4A2', 
  fontFamily='Crimson Text',
  fontSize='2.5em', 
  fontWeight='300',
  spacing='45px',
}) => (
  <h2
    css={css`
      display: inline-block;
      color: ${color};
      font-family: ${fontFamily};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      margin: 0 0 ${spacing} 0;
      text-align: ${align};
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    `}
  >
    {children}
  </h2>
)

export default Header2