import React from 'react'
import styled from '@emotion/styled'

// Styles
const ContainerBackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  z-index: -2;
  height: 100%;
  > div {
    height: 100%;
  }
`
const ContainerBackgroundBottomImageAccent = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
`
// Styles for Large Size
const ContainerLarge = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1000px;
  width: 100%;
  background: transparent;
  overflow: hidden;
  @media(max-width: 1100px) {
    min-height: 250px;
  }
`
// Styles for Small Size
const ContainerSmall = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  width: 100%;
  background: transparent;
  overflow: hidden;
  @media(max-width: 1099px) {
    min-height: 400px;
  }
  @media(max-width: 700px) {
    min-height: 250px;
  }
`

const Masthead = ({children, large=true, image, bottomAccent}) => (
  <>
    {large &&
      <ContainerLarge>

        <ContainerBackgroundImage>
          {image}
        </ContainerBackgroundImage>

        {children && 
          <>
            {children}
          </>
        }

        {bottomAccent &&
          <ContainerBackgroundBottomImageAccent>
            {bottomAccent}
          </ContainerBackgroundBottomImageAccent>
        }

      </ContainerLarge>
    }
    {!large &&
        <ContainerSmall>

          <ContainerBackgroundImage>
            {image}
          </ContainerBackgroundImage>

          {children && 
            <>
             {children}
            </>
          }
          
          {bottomAccent &&
            <ContainerBackgroundBottomImageAccent>
              {bottomAccent}
            </ContainerBackgroundBottomImageAccent>
          }

        </ContainerSmall>
    }
  </>
)

export default Masthead