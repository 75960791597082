import React from 'react' // eslint-disable-line no-unused-vars
/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Paragraph1 = ({
  children,
  color='#777777',
  spacing='60px',
  fontSize='1.375em', 
  fontFamily='Crimson Text', 
  fontWeight='300', 
  innerHTML
}) => (
  <>
    {innerHTML && 
      <p 
        css={css`
          line-height: 2;
          color: ${color};
          marigin: 0 0 ${spacing} 0;
          font-size: ${fontSize};
          font-family: ${fontFamily};
          font-weight: ${fontWeight};
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
        `} 
        dangerouslySetInnerHTML={{__html: innerHTML}}
      />
    }
    {children &&
      <p 
        css={css`
          line-height: 2;
          color: ${color};
          marigin: 0 0 ${spacing} 0;
          font-size: ${fontSize};
          font-family: ${fontFamily};
          font-weight: ${fontWeight};
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
        `}
      >
        {children}
      </p>
    }
  </>
)

export default Paragraph1